import React from "react";
import { navigate } from "gatsby-link";

import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import { Spinner } from "../../components/atoms/Spinner";

function Mobile({
  items,
  searchText,
  setSearchValue,
  activeItemId,
  seoIndicator = false,
  itemOnClick,
  isLoading,
  selectionCriteria,
}) {
  return (
    <Popover className={"block md:hidden"}>
      <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
        <nav
          className="relative flex items-center justify-between sm:h-10 lg:justify-start"
          aria-label="Global"
        >
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <div />
              <div className="-mr-2 flex items-center md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* Mobile Menu */}
      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between sticky top-0 w-full">
              <div className="bg-gray-200 w-full">
                <input
                  type="text"
                  className="bg-white w-full focus:outline-none focus:ring-0 py-5 text-sm font-display border-gray-200 border rounded-xl"
                  placeholder="Search Businesses"
                  value={searchText}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              {items &&
                items.map((service, s) => {
                  if (searchText.length > 0) {
                    if (
                      service.Name.toLowerCase().includes(
                        searchText.toLowerCase()
                      )
                    ) {
                      return (
                        <button
                          key={s}
                          onClick={
                            itemOnClick
                              ? itemOnClick(service)
                              : () =>
                                  navigate(
                                    `/admin/manage/business/${service.id}`
                                  )
                          }
                          className={`text-left font-display text-sm  block my-2 hover:bg-gray-200 rounded-2xl w-full p-3 ${
                            service.id == activeItemId
                              ? "font-bold text-white bg-brand-600"
                              : "text-gray-700 "
                          }`}
                        >
                          <div
                            className={`text-xs uppercase font-semibold rounded-lg text-white p-1 inline-block h-2 w-2 ${
                              service.seooptimized
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                          ></div>{" "}
                          {service.Name}
                        </button>
                      );
                    }
                  } else {
                    return (
                      <button
                        key={s}
                        onClick={
                          itemOnClick
                            ? itemOnClick(service)
                            : () =>
                                navigate(`/admin/manage/business/${service.id}`)
                        }
                        className={`text-left font-display text-sm block my-2 hover:bg-gray-200 rounded-2xl w-full p-3 ${
                          service.id == activeItemId
                            ? "font-bold text-white bg-brand-600"
                            : "text-gray-700 "
                        }`}
                      >
                        {service.Name}
                      </button>
                    );
                  }
                })}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

function Desktop({
  items,
  searchText,
  setSearchValue,
  activeItemId,
  seoIndicator = false,
  itemOnClick,
  isLoading,
  selectionCriteria,
  children,
}) {
  return (
    <div className="hidden md:block">
      <div className="bg-gray-200 sticky top-0">
        <input
          type="text"
          className="bg-white w-full focus:outline-none border-l-0 border-r-0 border-t-0 focus:ring-0 py-5 text-sm font-display border-b border-gray-200"
          placeholder="Search Businesses"
          value={searchText}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      {isLoading.isLoading && <Spinner size="6" className="mx-auto mt-10" />}
      {isLoading.message && (
        <p className="text-red-500 font-bold p-4">{isLoading.message}</p>
      )}
      <div className="overflow-scroll p-2">
        {items &&
          items.map((service, s) => {
            const itemsHandler = () => {
              if (searchText.length > 0) {
                if (
                  service.Name.toLowerCase().includes(searchText.toLowerCase())
                ) {
                  return (
                    <button
                      key={s}
                      onClick={
                        itemOnClick
                          ? itemOnClick(service)
                          : () =>
                              navigate(`/admin/manage/business/${service.id}`)
                      }
                      className={`text-left font-display text-sm block hover:bg-blue-200 rounded-xl w-full p-3 ${
                        service.id == activeItemId
                          ? "font-bold text-white bg-brand-600"
                          : "text-gray-700 "
                      }`}
                    >
                      {seoIndicator && (
                        <>
                          <div
                            className={`text-xs uppercase font-semibold rounded-lg text-white p-1 inline-block h-2 w-2 ${
                              service.seooptimized
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                          />{" "}
                        </>
                      )}
                      {service.Name}
                    </button>
                  );
                }
              } else {
                return (
                  <button
                    key={s}
                    onClick={
                      itemOnClick
                        ? itemOnClick(service)
                        : () => navigate(`/admin/manage/business/${service.id}`)
                    }
                    className={`text-left font-display text-sm block hover:bg-blue-200 rounded-xl w-full p-3 ${
                      service.id == activeItemId
                        ? "font-bold text-white bg-brand-600"
                        : "text-gray-700 "
                    }`}
                  >
                    {seoIndicator && (
                      <>
                        <div
                          className={`text-xs uppercase font-semibold rounded-lg text-white p-1 inline-block h-2 w-2 ${
                            service.seooptimized ? "bg-green-500" : "bg-red-500"
                          }`}
                        />{" "}
                      </>
                    )}
                    {service.Name}
                  </button>
                );
              }
            };

            if (typeof children === "function") {
              return children(service, {
                activeItemId,
                s,
                searchText,
                seoIndicator,
              });
            }
            return itemsHandler();
          })}
      </div>
    </div>
  );
}

export function LeftSidebar(props) {
  return (
    <div className="col-span-12 md:block md:col-span-2 md:border-r md:min-h-screen relative md:h-screen md:overflow-auto">
      <Mobile {...props} />
      <Desktop {...props} />
    </div>
  );
}
