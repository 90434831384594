import React, { useContext } from "react";
import { AuthContext } from "../components/AuthProvider";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import logo from "../images/localtier_logo.svg";
import {
  ArrowLeftIcon,
  LockClosedIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import { Menu } from "@headlessui/react";

// Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQyNzE0MzIxLCJleHAiOjE2NDUzMDYzMjF9.R92yM4pgp9dGSReN8zy0idD3Zde3wiYfzlOP07Bpdto bad token debug it
function AuthLayout({ children, bg, headerContent }) {
  const { user, login, logout, isUserLoggedIn } = useContext(AuthContext);

  return (
    <div className={`${bg == "gray" ? "bg-gray-50 h-screen" : "bg-white"}`}>
      <a
        className="sr-only sr-only-focusable w-full block bg-brand-500 text-white text-center focus:outline-none m-0 p-2"
        href="#main-content"
      >
        Skip to main content
      </a>
      <div>
        <div
          className={`relative bg-white shadow-lt`}
          style={{ boxShadow: "0 4px 4px -3px rgb(0 0 0 / 10%)" }}
        >
          <div className={`relative bg-white`}>
            <div className="mx-auto flex justify-between items-center px-4 py-4 sm:px-6 lg:px-8 md:justify-start md:space-x-10 border-b">
              <div className="flex">
                <button
                  onClick={() => navigate(-1)}
                  className="rounded-full  flex items-center text-gray-900 justify-center mr-2 font-display"
                >
                  <ArrowLeftIcon className="w-5 h-5 mr-1" /> Back
                </button>

                <Link to="/admin/dashboard">
                  <span className="sr-only">LocalTier</span>
                  <img className="h-8 w-auto ml-2" src={logo} alt="" />
                </Link>
              </div>
              <div className="hidden md:block md:w-1/12" />
              <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                {headerContent}
              </div>
              <div className="flex items-center md:ml-12">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex justify-center items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white  font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500">
                    <LockClosedIcon className="w-4 h-4 mr-1" /> {user.name}
                  </Menu.Button>
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => logout()}
                          className={`rounded-b-md block px-4 py-3 w-full text-left   ${
                            active && "bg-gray-50"
                          }`}
                        >
                          Log Out
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <main id="main-content">{children}</main>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthLayout;
