import React, { useContext } from "react";
import { navigate } from "gatsby";
import { AuthContext } from "./AuthProvider";

function PrivateRoute({ component: Component, location, ...rest }) {
  const { user, login, logout, isUserLoggedIn } = useContext(AuthContext);
  console.log(
    "location.pathname",
    location.pathname,
    !isUserLoggedIn() && location.pathname !== `/admin`,
    !isUserLoggedIn(),
    location.pathname !== `/admin`
  );
  if (!isUserLoggedIn() && location.pathname !== `/admin`) {
    navigate("/admin");
    return null;
  }

  return <Component {...rest} />;
}

export default PrivateRoute;
