import React from "react";
import { Router } from "@reach/router";
import Login from "../../admin/login";
import ManageRank from "../../admin/manage/rank";
import PrivateRoute from "../../components/PrivateRoute";
import PublicRoute from "../../components/PublicRoute";
import { AuthProvider } from "../../components/AuthProvider";
import Loadable from "react-loadable";

const Loading = (props) => {
  return <div>Loading...</div>;
};

const AsyncProfile = Loadable({
  loader: () => import("../../admin/profile"),
  loading: Loading,
});

const AsyncDashboard = Loadable({
  loader: () => import("../../admin/dashboard"),
  loading: Loading,
});

const AsyncManageRank = Loadable({
  loader: () => import("../../admin/manage/rank"),
  loading: Loading,
});

const AsyncManageRankPreview = Loadable({
  loader: () => import("../../admin/manage/rank-preview"),
  loading: Loading,
});

const AsyncManageBusiness = Loadable({
  loader: () => import("../../admin/manage/business"),
  loading: Loading,
});
const AsyncCreateBusiness = Loadable({
  loader: () => import("../../admin/manage/business-create"),
  loading: Loading,
});

const AsyncManageJobs = Loadable({
  loader: () => import("../../admin/manage/jobs"),
  loading: Loading,
});
const AsyncManageLicense = Loadable({
  loader: () => import("../../admin/manage/license"),
  loading: Loading,
});

const App = () => {
  return (
    <div>
      <AuthProvider>
        <Router basepath="/admin">
          <PrivateRoute path="/profile" component={AsyncProfile} />
          <PrivateRoute path="/dashboard" component={AsyncDashboard} />
          <PrivateRoute path="/manage/rank" component={AsyncManageRank} />

          <PrivateRoute
            path="/manage/rank/:cityid/:serviceid"
            component={AsyncManageRank}
          />

          <PrivateRoute
            path="/manage/rank/preview/:cityid/:serviceid"
            component={AsyncManageRankPreview}
          />

          <PrivateRoute
            path="/manage/business/create"
            component={AsyncCreateBusiness}
          />
          <PrivateRoute
            path="/manage/business/:businessId"
            component={AsyncManageBusiness}
          />

          <PrivateRoute
            path="/manage/business/"
            component={AsyncManageBusiness}
          />
          <PrivateRoute path="/manage/jobs/" component={AsyncManageJobs} />
          <PrivateRoute
            path="/manage/jobs/:jobId"
            component={AsyncManageJobs}
          />
          <PrivateRoute
            path="/manage/licenses/"
            component={AsyncManageLicense}
          />
          <PrivateRoute
            path="/manage/licenses/:licenseId"
            component={AsyncManageLicense}
          />
          <PublicRoute path="/" component={Login} />
        </Router>
      </AuthProvider>
    </div>
  );
};

export default App;
