import React, { useState, useEffect, useContext, Suspense } from "react";
import { AuthContext } from "../../components/AuthProvider";
import AuthLayout from "../AuthLayout";
import { Link } from "gatsby";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { navigate } from "gatsby-link";
import "../manage/easymde.min.css";
import { CogIcon } from "@heroicons/react/solid";
import Button from "../../components/atoms/Button";
import { Switch } from "@headlessui/react";
import { Spinner } from "../../components/atoms/Spinner";
import { LeftSidebar } from "../components/sidebar";

import { useFetch } from "../../hooks/useFetch";

const LazySimpleMdeReact = React.lazy(() => import("./editor"));

const SimpleMdeReact = ({ ...props }) => (
  <Suspense fallback={<div>Loading</div>}>
    <LazySimpleMdeReact {...props} />
  </Suspense>
);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function RankItem({ business, index }) {
  return (
    <Draggable key={index} draggableId={index.toString()} index={index}>
      {(provided) => (
        <div
          className="p-3 border bg-white font-semibold my-1 rounded-xl"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <span className="text-gray-400">{index + 1}</span> {business.Name}
          <Button
            onClick={() => navigate(`/admin/manage/business/${business.id}`)}
            size="small"
            color="secondary"
            className="mt-2"
          >
            Edit Business
          </Button>
        </div>
      )}
    </Draggable>
  );
}

function ManageRank({ cityid, serviceid }) {
  const { token } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [cities, updateCities] = useFetch(
    `${process.env.REACT_APP_API_ENDPOINT}/cities/?_itemsList=1`
  );
  const [services, updateServices] = useFetch(
    `${process.env.REACT_APP_API_ENDPOINT}/returnServicesNamesAndIDs/?_sort=Name:ASC&_limit=10000&_itemsList=1`
  );

  const [selectedCity, setSelectedCity] = useState(cityid ? cityid : 2);
  const [selectedService, setSelectedService] = useState(
    serviceid ? serviceid : null
  );

  const [serviceAreaRank, setServiceAreaRank] = useState(null);
  const [loadingRankList, setLoadingRankList] = useState(null);
  const [loadingServices, setLoadingServices] = useState(true);

  const [searchCity, setSearchCity] = useState("");

  const [savingInProgress, setSavingInProgress] = useState({
    content: false,
    rankedList: false,
  });

  const [contentStates, updateContent] = useState(
    serviceAreaRank && serviceAreaRank.service
      ? {
          excerpt: serviceAreaRank.service.excerpt,
          introContent: serviceAreaRank.service.introcontent,
          footerContent: serviceAreaRank.service.footercontent,
          seooptimized: serviceAreaRank.service.seooptimized,
        }
      : {
          excerpt: "",
          introContent: "",
          footerContent: "",
          seooptimized: false,
        }
  );

  const { excerpt, introContent, footerContent, seooptimized } = contentStates;

  const [selectedTab, setSelectedTab] =
    (React.useState < "write") | ("preview" > "write");

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const serviceAreaRankList = reorder(
      serviceAreaRank.RankedList,
      result.source.index,
      result.destination.index
    );

    setServiceAreaRank({ ...serviceAreaRank, RankedList: serviceAreaRankList });
  }

  const [
    rankedListDoesNotExistForCityServiceSelection,
    setRankedListDoesNotExistForCityServiceSelection,
  ] = useState(false);

  useEffect(() => {
    //if both a city and service are selected, go find the area rank
    if (selectedCity && selectedService) {
      //@TODO save selections in URL to allow for sharing links that would directly pull the service area rank
      //without needing to select city and service every time
      // navigate(`/admin/manage/rank/${selectedCity}/${selectedService}`)

      getServiceAreaRankByCityIDAndServiceID(selectedCity, selectedService);
      navigate(`/admin/manage/rank/${selectedCity}/${selectedService}`);
    }
  }, [selectedCity, selectedService]);

  async function getServiceAreaRankByCityIDAndServiceID(city, service) {
    setLoadingRankList(true);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/service-area-ranks?city=${city}&service=${service}`,
        config
      )
      .then((response) => {
        // Handle success.
        setLoadingRankList(false);
        console.log(response.data.length);

        //this ranking list page does not exist
        if (response.data.length < 1) {
          setRankedListDoesNotExistForCityServiceSelection(true);
        } else {
          setRankedListDoesNotExistForCityServiceSelection(false);
        }

        setServiceAreaRank(response.data[0]);

        updateContent({
          excerpt: response.data[0].service.excerpt,
          introContent: response.data[0].service.introcontent,
          footerContent: response.data[0].service.footercontent,
          seooptimized: response.data[0].service.seooptimized,
        });

        console.log("Content states", contentStates);
      })
      .catch((error) => {
        // Handle error.
        setLoadingRankList(false);
        console.log("An error occurred:", error.response);
      });
  }

  if (typeof window !== "undefined") {
    return (
      <AuthLayout>
        <meta charset="utf-8" />
        <div className="mx-auto">
          <div className="grid grid-cols-12">
            <LeftSidebar
              items={services.data}
              searchText={searchCity}
              setSearchValue={setSearchCity}
              seoIndicator
              activeItemId={selectedService}
              itemOnClick={(service) => () => setSelectedService(service.id)}
              isLoading={{
                isLoading: services.isLoading,
                message: services.loadingMessage,
              }}
            />
            {/* <div className="col-span-2">
                {cities &&
                  cities.map((city, c) => (
                    <button
                    key={c}
                      onClick={() => setSelectedCity(city.id)}
                      className={`font-display text-sm text-gray-700 block border w-full p-2 ${
                        city.id == selectedCity
                          ? "font-bold text-white bg-brand-600"
                          : ""
                      }`}
                    > 
                      {city.Name}
                    </button>
                  ))}
              </div> */}
            <div className="col-span-12 md:col-span-10">
              {!selectedService || !selectedCity ? (
                <p>select a city and a service to view rankings</p>
              ) : null}

              {loadingRankList ? (
                <Spinner className="m-6 mx-auto mt-20" size="6" />
              ) : null}

              {rankedListDoesNotExistForCityServiceSelection ? (
                <p className="text-red-500">
                  This ranked list does not exist, would you like to create it?
                </p>
              ) : (
                ""
              )}

              {serviceAreaRank &&
              !loadingRankList &&
              !rankedListDoesNotExistForCityServiceSelection ? (
                <div>
                  <div className="shadow-lt bg-white py-6  sm:px-6 lg:px-8 flex justify-between border-b">
                    <div>
                      <h1 className="font-bold text-lg font-display text-gray-900 flex items-center">
                        <CogIcon className="h-5 w-5 mr-1 text-gray-600" />
                        {serviceAreaRank.service &&
                          serviceAreaRank.service.Name}{" "}
                        in {serviceAreaRank.city.Name}
                      </h1>
                      <Link
                        to={`/${serviceAreaRank.city.Slug}/${serviceAreaRank.service.Slug}/`}
                        className="text-sm text-gray-600"
                      >
                        /{serviceAreaRank.city.Slug}/
                        {serviceAreaRank.service.Slug}
                      </Link>
                    </div>{" "}
                    <div className="flex items-center">
                      <Button
                        color="secondary"
                        href={`/admin/manage/rank/preview/${selectedCity}/${selectedService}`}
                        className="mr-2"
                      >
                        Preview{" "}
                        <span className="ml-1 text-gray-400 text-xs uppercase">
                          BETA
                        </span>
                      </Button>
                      <Button
                        loading={
                          savingInProgress.content ||
                          savingInProgress.rankedList
                        }
                        onClick={() => {
                          setSavingInProgress({
                            content: true,
                            rankedList: true,
                          });
                          const serviceBody = {
                            excerpt: excerpt,
                            introcontent: introContent,
                            footercontent: footerContent,
                            seooptimized: seooptimized,
                          };
                          axios
                            .put(
                              `${process.env.REACT_APP_API_ENDPOINT}/services/${selectedService}`,
                              serviceBody,
                              config
                            )
                            .then((response) => {
                              // Handle success.
                              setTimeout(() => {
                                setSavingInProgress({
                                  ...savingInProgress,
                                  content: false,
                                });
                                console.log("set to false now after 5");
                              }, 5000);

                              console.log(response.data.length);
                            })
                            .catch((error) => {
                              // Handle error.

                              console.log("An error occurred:", error.response);
                            });
                          const serviceRankListBody = {
                            RankedList: serviceAreaRank.RankedList.map(
                              ({ id, business }) => ({
                                id: id,
                                business: business,
                              })
                            ),
                          };
                          axios
                            .put(
                              `${process.env.REACT_APP_API_ENDPOINT}/service-area-ranks/${serviceAreaRank.id}`,
                              serviceRankListBody,
                              config
                            )
                            .then((response) => {
                              // Handle success.
                              setSavingInProgress({
                                ...savingInProgress,
                                rankedList: false,
                              });
                              console.log(response.data.length);
                            })
                            .catch((error) => {
                              // Handle error.

                              console.log("An error occurred:", error.response);
                            });
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  <div className=" grid grid-cols-10">
                    <div className="col-span-10 md:col-span-7">
                      <div className="p-4 md:p-0 w-full md:max-w-4xl mx-auto">
                        <div className="rounded-xl my-4">
                          <p className="font-semibold text-lg">Excerpt</p>
                          <SimpleMdeReact
                            value={contentStates.excerpt}
                            onChange={(e) =>
                              updateContent({
                                ...contentStates,
                                excerpt: e,
                              })
                            }
                          />
                        </div>
                        <div className="rounded-xl my-4">
                          <p className="font-semibold text-lg">Intro Content</p>
                          <SimpleMdeReact
                            value={contentStates.introContent}
                            onChange={(e) =>
                              updateContent({
                                ...contentStates,
                                introContent: e,
                              })
                            }
                          />
                        </div>
                        <div className="rounded-xl my-4">
                          <p className="font-semibold text-lg">
                            Footer Content
                          </p>
                          <SimpleMdeReact
                            value={contentStates.footerContent}
                            onChange={(e) =>
                              updateContent({
                                ...contentStates,
                                footerContent: e,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-10 md:col-span-3 border-l min-h-screen">
                      <div className="border-b p-2">
                        <div className="p-3 flex items-center">
                          <Switch
                            checked={contentStates.seooptimized}
                            onChange={(e) =>
                              updateContent({
                                ...contentStates,
                                seooptimized: e,
                              })
                            }
                            className={`${
                              contentStates.seooptimized
                                ? "bg-green-600"
                                : "bg-gray-200"
                            } relative inline-flex items-center h-6 rounded-full w-11`}
                          >
                            <span className="sr-only">
                              Enable notifications
                            </span>
                            <span
                              className={`${
                                contentStates.seooptimized
                                  ? "translate-x-6"
                                  : "translate-x-1"
                              } inline-block w-4 h-4 transform bg-white rounded-full`}
                            />
                          </Switch>
                          <p className="font-semibold inline ml-2">
                            SEO Optimized
                          </p>
                        </div>
                      </div>{" "}
                      <div className="border-b p-3">
                        <p className="font-semibold text-lg mb-2">
                          Business Rank List
                        </p>

                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="list">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {serviceAreaRank.RankedList &&
                                  serviceAreaRank.RankedList.map(
                                    (rankedList, r) => (
                                      <RankItem
                                        business={rankedList.business}
                                        index={r}
                                        key={r}
                                      />
                                    )
                                  )}

                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>{" "}
        </div>
      </AuthLayout>
    );
  } else return <p>Window not defined </p>;
}

export default ManageRank;
