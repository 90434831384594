import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";

import { AuthContext } from "../components/AuthProvider";
import axios from "axios";

export const useFetch = (
  url,
  initialState,
  options = { lazy: false },
  fetchCompletedHook
) => {
  const abortController = useRef(new AbortController());
  const { token } = useContext(AuthContext);

  const [fetchState, updateFetchState] = useState({
    data: null,
    ...initialState,
    isLoading: true,
    loadingMessage: "Fetching",
  });

  const fetch = useCallback(() => {
    abortController.current = new AbortController();
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: options.method || "GET",
        url,
        signal: abortController.current.signal,
      };
      updateFetchState((prevFetchState) => ({
        ...prevFetchState,
        isLoading: true,
        loadingMessage: "Fetching",
      }));

      axios(
        options.config
          ? {
              ...config,
              ...options.config,
              url,
              signal: abortController.current.signal,
            }
          : config
      )
        .then((response) => {
          // Handle success.
          updateFetchState((prevFetchState) => ({
            ...prevFetchState,
            data: response.data,
            isLoading: false,
            loadingMessage: "",
          }));
          if (fetchCompletedHook) fetchCompletedHook(response);
        })
        .catch((error) => {
          // Handle error.
          updateFetchState((prevFetchState) => ({
            ...prevFetchState,
            data: null,
            isLoading: false,
            loadingMessage: "",
          }));
          console.log("An error occurred:", error.response);
        });
    }
  }, [url, token]);

  useEffect(() => {
    if (!options.lazy) {
      fetch();
    }
    return () => {
      abortController.current.abort();
    };
  }, [url, token]);

  return [fetchState, updateFetchState, fetch];
};
