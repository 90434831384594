import React, { useState, useContext } from "react";
import { AuthContext } from "../components/AuthProvider";
import { Link} from "gatsby";

function Login() { 
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { user, login, logout, isUserLoggedIn, loginErrors } = useContext(AuthContext);
 
    return <div className="bg-gradient-to-b from-blue-100 to-white min-h-screen">
        
         <div className="max-w-lg mx-auto  px-8">
       
        <h1 className="font-bold text-gray-900  mt-8 text-xl md:text-2xl mb-2 font-display">Welcome</h1>
        <p className="text-sm md:text-base text-gray-600">
          By logging in you accept our Privacy Policy and Terms of Service.
        </p>
        
    
      </div>

        <form className="max-w-xl mx-auto p-12" onSubmit={(e) => {e.preventDefault(); login(username, password)}}>
        {loginErrors && loginErrors.messages && loginErrors.messages.map((error, r)=>(
           <p key={r}>{error.message}</p>
         ))}

          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-700">
              Username
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="username" type="username" required   value={username}      onChange={(e) => setUsername(e.target.value)} className="bg-white appearance-none block w-full p-3  border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-brand focus:border-brand-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
            </div>
          </div>
  
          <div className="mt-6">
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
              Password
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="password" type="password" required value={password} onChange={(e) => setPassword(e.target.value)} className="bg-white appearance-none block w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-brand focus:border-brand-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
            </div>
          </div>
  
          
  
          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-500 focus:outline-none focus:border-brand-700 focus:shadow-outline-brand active:bg-brand-700 transition duration-150 ease-in-out">
                  Signin
              </button>
            </span>
          </div>
        </form>


    </div>
}

export default Login